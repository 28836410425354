import { UserProfile } from "@greenflagdate/shared";
import { useReq } from "@larner.dev/use-req";
import { Alert, Dialog, DialogProps } from "evergreen-ui";
import { apiReq } from "../../lib/apiReq";

interface UnmatchDialogProps extends DialogProps {
  unmatchUserProfile: UserProfile;
  onUnmatch?: () => void;
}
export const UnmatchDialog = ({
  unmatchUserProfile,
  onUnmatch,
  ...props
}: UnmatchDialogProps) => {
  const [request, requestState] = useReq(apiReq);
  return (
    <Dialog
      title="Confirm Unmatch"
      intent="danger"
      confirmLabel="Unmatch"
      isConfirmLoading={requestState.loading}
      onConfirm={async () => {
        const response = await request.delete("/user/relationship", {
          userId: unmatchUserProfile.id,
        });
        if (response.success) {
          if (props.onCloseComplete) {
            props.onCloseComplete();
          }
          if (onUnmatch) {
            onUnmatch();
          }
        }
      }}
      {...props}
    >
      {!!requestState.error && <Alert title={requestState.error.message} />}
      Are you sure you want to unmatch with{" "}
      <strong>{unmatchUserProfile.firstName}</strong>
    </Dialog>
  );
};
