import classNames from "classnames";
import { Button } from "../../../components/Button/Button";
import { Pane, Paragraph } from "evergreen-ui";
import { OldDateField } from "../../../components/DateField/OldDateField";
import { H1, H2, H3, Heading } from "../../../components/Heading/Heading";
import { TextField } from "../../../components/TextField/TextField";
import styles from "./Event.module.css";
import { useOutletContext } from "react-router-dom";
import { Card } from "../../../components/Card/Card";
import { PageHeader } from "../../../components/PageHeader/PageHeader";
import { faFire } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tag } from "../../../components/Tag/Tag";
import dayjs from "dayjs";
import { HashLink } from "react-router-hash-link";
import { CMSEventAttributes, CMSItem } from "@greenflagdate/shared";
import {
  FormEventHandler,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { useGFTheme } from "../../../lib/themes";
import validator from "validator";
import Markdown from "react-markdown";
import { Link } from "../../../components/Link/Link";
import { GenderSelector } from "../../../components/GenderSelector/GenderSelector";
import { GenderPreferenceSelector } from "../../../components/GenderPreferenceSelector/GenderPreferenceSelector";

export function EventDetails() {
  const { event, url } = useOutletContext<{
    event: CMSItem<CMSEventAttributes>;
    url: string;
  }>();
  const [gender, setGender] = useState<string | undefined>();
  const [genderPreference, setGenderPreference] = useState<
    string | undefined
  >();
  const { colors } = useGFTheme();
  const form = useRef<HTMLFormElement>(null);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [date, setDate] = useState("");
  const waitList = useMemo(
    () => dayjs(event.attributes.registrationEndsAt).isBefore(dayjs()),
    [event.attributes.registrationEndsAt]
  );
  const submit: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      if (form.current) {
        const errors: Record<string, string> = {};
        const data = new FormData(form.current);
        if (!data.get("first_name")) {
          errors.first_name = "Please enter your first name";
        }
        if (!data.get("email")) {
          errors.email = "Please enter your email";
        } else if (!validator.isEmail(data.get("email")?.toString() || "")) {
          errors.email = "Please check your email";
        }
        if (!data.get("birth_date")) {
          errors.birth_date = "Please enter your birth date";
        }
        if (!data.get("postal_code")) {
          errors.postal_code = "Please enter your zip code";
        }
        if (!gender) {
          errors.gender = "Please select one";
        }
        if (!genderPreference) {
          errors.dating_preference = "Please select at least one";
        }
        if (Object.keys(errors).length) {
          setErrors(errors);
          e.preventDefault();
        } else {
          // posthog.capture("eventSignUp", {
          //   slug: event.attributes.slug,
          //   $set: { email: data.get("email") },
          // });
          (document.getElementById("gender") as HTMLInputElement).value =
            gender!;
          (
            document.getElementById("dating_preference") as HTMLInputElement
          ).value = genderPreference!;

          const isMan = ["man", "transgender man"].includes(gender || "");
          const isWoman = ["woman", "transgender woman"].includes(gender || "");
          const sendToWaitList =
            waitList ||
            (event.attributes.menSpotsLeft === 0 &&
              event.attributes.womenSpotsLeft === 0) ||
            (isMan && event.attributes.menSpotsLeft === 0) ||
            (isWoman && event.attributes.womenSpotsLeft === 0);

          const isLGBTQ =
            !["man", "woman"].includes(gender!.toLowerCase()) ||
            (gender === "man" &&
              !genderPreference!.includes("woman") &&
              !genderPreference!.includes("everyone")) ||
            (gender === "woman" &&
              !genderPreference!.includes("man") &&
              !genderPreference!.includes("everyone"));
          (document.getElementById("is_lgbtq") as HTMLInputElement).value =
            isLGBTQ ? "true" : "false";

          form.current.action = `https://customerioforms.com/forms/submit_action?site_id=${import.meta.env.VITE_CIO_SITE_ID
            }&form_id=${sendToWaitList ? "waitlist-" : "registered-"}${event.attributes.slug
            }&success_url=${import.meta.env.VITE_WEB_URL}${url}/${event.attributes.priceId && !sendToWaitList
              ? "checkout"
              : `success${sendToWaitList ? encodeURIComponent("?waitlist=true") : ""
              }`
            }`;
        }
      } else {
        e.preventDefault();
      }
    },
    [
      event.attributes.menSpotsLeft,
      event.attributes.priceId,
      event.attributes.slug,
      event.attributes.womenSpotsLeft,
      gender,
      genderPreference,
      url,
      waitList,
    ]
  );

  return (
    <div className={styles.gfc}>
      <PageHeader>
        <PageHeader.Title whiteSpace="pre-wrap">
          {event.attributes.title}
        </PageHeader.Title>
        <PageHeader.Subtitle>
          <Markdown
            components={{
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              h1({ is, ref, ...props }) {
                return <H1 {...props} />;
              },
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              h2({ is, ref, ...props }) {
                return <H2 {...props} />;
              },
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              h3({ is, ref, ...props }) {
                return <H3 {...props} />;
              },
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              p({ is, ref, ...props }) {
                return (
                  <Paragraph size={500} color={colors.greenDark} {...props} />
                );
              },
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              a({ is, ref, href, ...props }) {
                return (
                  <Link
                    to={href || ""}
                    {...props}
                    color={colors.greenDark}
                    selectors={{ "&:hover": { color: colors.greenPrimary } }}
                  />
                );
              },
            }}
          >
            {event.attributes.subtitle}
          </Markdown>
        </PageHeader.Subtitle>
        <Button
          label={
            dayjs(event.attributes.registrationEndsAt).isAfter(dayjs())
              ? "REGISTER"
              : "JOIN THE WAIT LIST"
          }
          size="medium"
          type="submit"
          href={`${url}#join`}
          component="hash-link"
        />
      </PageHeader>
      <div className={styles.mainContent}>
        <Pane display="flex">
          {typeof event.attributes.womenSpotsLeft === "number" &&
            (event.attributes.womenSpotsLeft > 0 ? (
              <Pane marginRight="1rem">
                <Tag
                  iconAfter={
                    <FontAwesomeIcon
                      icon={faFire}
                      color={colors.orangePrimary}
                      fontSize="0.75rem"
                    />
                  }
                  backgroundColor={colors.orangePrimary + "5A"}
                >
                  Spots remaining for Women: {event.attributes.womenSpotsLeft}
                </Tag>
              </Pane>
            ) : (
              <Pane marginRight="1rem">
                <Tag backgroundColor={colors.neutralDark + "1A"}>
                  Spots remaining for Women: Full
                </Tag>
                {/* @ts-expect-error types bad? */}
                <HashLink
                  to={`${url}#join`}
                  style={{
                    display: "block",
                    marginTop: "0.5rem",
                    textDecoration: "underline",
                    textAlign: "center",
                  }}
                >
                  Join the wait list
                </HashLink>
              </Pane>
            ))}
          {typeof event.attributes.menSpotsLeft === "number" &&
            (event.attributes.menSpotsLeft > 0 ? (
              <Tag
                iconAfter={
                  <FontAwesomeIcon
                    icon={faFire}
                    color={colors.orangePrimary}
                    fontSize="0.75rem"
                  />
                }
                backgroundColor={colors.orangePrimary + "5A"}
              >
                Spots remaining for Men: {event.attributes.menSpotsLeft}
              </Tag>
            ) : (
              <Tag
                backgroundColor={colors.neutralDark + "1A"}
                marginRight="1rem"
              >
                Spots remaining for Men: Full - Join the Wait List
              </Tag>
            ))}
        </Pane>
        {event.attributes.price > 0 && (
          <Paragraph size={400}>ADMISSION: ${event.attributes.price}</Paragraph>
        )}

        {!!event.attributes.description && (
          <Pane marginTop="1rem" selectors={{ "& img": { width: "100%" } }}>
            <Markdown
              components={{
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                h1({ is, ref, ...props }) {
                  return <H1 {...props} />;
                },
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                h2({ is, ref, ...props }) {
                  return <H2 {...props} />;
                },
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                h3({ is, ref, ...props }) {
                  return <H3 {...props} />;
                },
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                p({ is, ref, ...props }) {
                  return (
                    <Paragraph size={500} color={colors.greenDark} {...props} />
                  );
                },
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                a({ is, ref, href, ...props }) {
                  return <Link to={href || ""} {...props} />;
                },
              }}
            >
              {event.attributes.description}
            </Markdown>
          </Pane>
        )}
      </div>
      <a id="join" style={{ position: "relative", top: "-5rem" }} />
      <div className={styles.formContainer}>
        <div className={styles.formContent}>
          <Card className={styles.formCard}>
            <Heading size={2} className={styles.title}>
              Join the {waitList ? "wait list" : "event"}
            </Heading>

            {!waitList && (
              <Paragraph>
                If spots are full you will be added to the wait list instead
              </Paragraph>
            )}

            <form
              // action={`https://customerioforms.com/forms/submit_action?site_id=24a148f7ff1ee70c7a7b&form_id=${}&success_url=https://www.greenflagdate.com/gfc/checkout`}
              method="POST"
              ref={form}
              onSubmit={submit}
            >
              <label className={styles.question}>
                <div className={styles.questionLabel}>
                  First Name <span className={styles.required}>*</span>
                </div>
                <TextField
                  fullWidth
                  name="first_name"
                  error={errors.first_name}
                />
              </label>
              <label className={styles.question}>
                <div className={styles.questionLabel}>
                  Email <span className={styles.required}>*</span>
                </div>
                <TextField fullWidth name="email" error={errors.email} />
              </label>
              <label className={styles.question}>
                <div className={styles.questionLabel}>
                  Birth Date <span className={styles.required}>*</span>
                </div>
                <OldDateField
                  fullWidth
                  onChange={setDate}
                  value={date}
                  error={errors.birth_date}
                  name="birth_date"
                />
              </label>
              <label className={styles.question}>
                <div className={styles.questionLabel}>
                  Zip Code <span className={styles.required}>*</span>
                </div>
                <TextField
                  fullWidth
                  type="number"
                  name="postal_code"
                  error={errors.postal_code}
                />
              </label>
              <div className={classNames(styles.group, styles.question)}>
                <div className={styles.questionLabel}>
                  What is your gender?{" "}
                  <span className={styles.required}>*</span>
                </div>
                <GenderSelector onChange={setGender} value={gender} />
                {errors.gender && (
                  <div className={styles.error}>{errors.gender}</div>
                )}
              </div>
              <div className={classNames(styles.group, styles.question)}>
                <div className={styles.questionLabel}>
                  Who do you want to date?{" "}
                  <span className={styles.required}>*</span>
                </div>

                <GenderPreferenceSelector
                  value={genderPreference}
                  onChange={setGenderPreference}
                />
                {errors.dating_preference && (
                  <div className={styles.error}>{errors.dating_preference}</div>
                )}
              </div>
              {event.attributes.referralsEnabled && (
                <>
                  <div className={classNames(styles.group, styles.question)}>
                    <div className={styles.questionLabel}>
                      Did someone refer you?
                    </div>

                    <div style={{ marginBottom: "1rem" }}>Referer Name</div>
                    <TextField
                      fullWidth
                      name="refer_name"
                      style={{ marginBottom: "1rem" }}
                    />
                    <div style={{ marginBottom: "1rem" }}>
                      Referer Email Address
                    </div>
                    <TextField fullWidth name="refer_email" />
                  </div>
                </>
              )}
              <input type="hidden" name="is_lgbtq" value="" id="is_lgbtq" />
              <input type="hidden" name="gender" value="" id="gender" />
              <input
                type="hidden"
                name="dating_preference"
                value=""
                id="dating_preference"
              />

              <Button
                label="SIGN UP"
                size="large"
                type="submit"
                className={styles.button}
              />
            </form>
          </Card>
        </div>
      </div>
    </div>
  );
}
