import { Button, Pane, Paragraph } from "evergreen-ui";
import { H2, H3 } from "../../../components/Heading/Heading";
import { useNavigate } from "react-router-dom";
import {
  GenderPreference,
  SparkPreference,
  UserWithMediaAndGroups,
} from "@greenflagdate/shared";
import { useReq } from "@larner.dev/use-req";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { apiReq } from "../../../lib/apiReq";
import { Loader } from "../../../components/Loader/Loader";
import {
  PaymentForm,
  PaymentFormHandle,
} from "../../../components/PaymentForm/PaymentForm";
import { GenderPreferenceSelector } from "../../../components/GenderPreferenceSelector/GenderPreferenceSelector";
import { store, useStoreData } from "../../../lib/store/store";
import { useLoggedIn } from "../../../lib/useLoggedIn";
import { ActionType } from "../../../lib/store/storeActions";
import Slider from "rc-slider";
import dayjs from "dayjs";
import debounce from "lodash.debounce";
import { Alert } from "../../../components/Alert/Alert";
import { ContactPreferencesForm } from "../../../components/ContactPreferencesForm/ContactPreferencesForm";

const debouncedSaveAgeRange = debounce(
  async (range: number[], updateUserReq) => {
    const response = await updateUserReq.put("/user/attributes", {
      min_age_preference: range[0],
      max_age_preference: range[1],
    });
    if (response.success) {
      store.dispatch({
        type: ActionType.UpdateUser,
        params: { user: response.result },
      });
    }
  },
  1000
);

export const Settings = () => {
  const storeData = useStoreData();
  useLoggedIn(storeData);
  const navigate = useNavigate();
  const [latestPrefReq, latestPrefReqState] = useReq<SparkPreference | null>(
    apiReq
  );
  const paymentFormRef = useRef<PaymentFormHandle>(null);
  const [updateUserReq, updateUserReqState] =
    useReq<UserWithMediaAndGroups>(apiReq);
  const [paymentFormLoading, setPaymentFormLoading] = useState(false);
  const [editingCard, setEditingCard] = useState(false);
  const [genderPreferenceOverride, setGenderPreferenceOverride] = useState<
    GenderPreference | undefined
  >();
  const [ageRangeOverride, setAgeRangeOverride] = useState<
    number[] | undefined
  >();

  const ageRange = useMemo(() => {
    const age = storeData.sparkRegistration?.birthDate
      ? dayjs().diff(storeData.sparkRegistration.birthDate, "year")
      : 30;
    const gap = Math.min(10, age - Math.ceil(age / 2 + 7));
    return (
      ageRangeOverride || [
        storeData.user?.min_age_preference || Math.max(21, age - gap),
        storeData.user?.max_age_preference || Math.min(100, age + gap),
      ]
    );
  }, [
    ageRangeOverride,
    storeData.sparkRegistration.birthDate,
    storeData.user?.max_age_preference,
    storeData.user?.min_age_preference,
  ]);
  const saveAgeRange = useCallback(
    (range: number[]) => {
      debouncedSaveAgeRange(range, updateUserReq);
    },
    [updateUserReq]
  );
  useEffect(() => {
    latestPrefReq.get("/spark/preferences/latest");
  }, [latestPrefReq]);
  return (
    <Pane
      maxWidth="40rem"
      marginX="auto"
      marginTop="1.5rem"
      paddingX="1.5rem"
      paddingBottom="2rem"
    >
      <H2 marginBottom="1rem">My Settings</H2>
      {!!updateUserReqState.error && (
        <Alert title={updateUserReqState.error.code} />
      )}
      {!!storeData.user?.first_name && (
        <Paragraph size={500}>
          You're logged in as {storeData.user?.first_name}
        </Paragraph>
      )}

      <H3 flex={1} marginTop="3rem">
        Contact Preferences
      </H3>
      <Paragraph size={500} marginTop="1rem">
        When there is a mutual match, this information will be shared with the
        person you match with.
      </Paragraph>
      <ContactPreferencesForm />

      <H3 marginBottom="1rem" marginTop="3rem">
        Who do you want to date?
      </H3>
      <GenderPreferenceSelector
        value={genderPreferenceOverride || storeData.user?.gender_preference}
        onChange={async (value?: GenderPreference) => {
          if (value) {
            setGenderPreferenceOverride(value);
            const response = await updateUserReq.put("/user/attributes", {
              gender_preference: value,
            });
            if (response.success) {
              store.dispatch({
                type: ActionType.UpdateUser,
                params: { user: response.result },
              });
            }
            setGenderPreferenceOverride(undefined);
          }
        }}
      />
      <H2
        marginTop="1.5rem"
        width="100%"
        fontSize="1rem"
        fontWeight={500}
        marginBottom="1rem"
      >
        Age Preference
      </H2>
      <Slider
        range
        min={18}
        max={70}
        value={ageRange}
        onChange={async (newRange) => {
          setAgeRangeOverride(newRange as number[]);
          saveAgeRange(newRange as number[]);
        }}
        styles={{ handle: { width: 30, height: 30, marginTop: "-12px" } }}
      />
      <Paragraph size={500} width="100%" textAlign="center" marginTop="1rem">
        {ageRange[0]} - {ageRange[1]}
        <br />
        years old
      </Paragraph>
      <Pane display="flex" marginTop="3rem">
        <H3 flex={1}>Spark Preferences</H3>
        {!!latestPrefReqState.result && (
          <Button size="small" onClick={() => navigate("/spark/preferences")}>
            Update
          </Button>
        )}
      </Pane>
      {latestPrefReqState.loading ? (
        <Loader />
      ) : latestPrefReqState.result ? (
        <>
          <Paragraph size={500} marginTop="1rem">
            The more flexible you are with your Spark preferences, the more
            likely you'll be placed in a group!
          </Paragraph>
        </>
      ) : (
        <>
          <Paragraph size={500} marginTop="1rem">
            You haven't filled out your spark preferences yet.
          </Paragraph>
          <Button
            appearance="primary"
            marginTop="1rem"
            onClick={() => navigate("/spark")}
          >
            Sign up for your first Spark!
          </Button>
        </>
      )}
      <H3 marginTop="3rem" marginBottom="1rem">
        Payment Method
      </H3>
      <PaymentForm
        ref={paymentFormRef}
        onLoadingStatusChange={setPaymentFormLoading}
        onEditCardChange={setEditingCard}
      />
      {editingCard && (
        <Button
          appearance="primary"
          onClick={() => paymentFormRef.current?.submitForm()}
          isLoading={paymentFormLoading}
        >
          Save Card
        </Button>
      )}
    </Pane>
  );
};
