import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Page } from "../components/Page/Page";
import { Contact } from "../pages/marketing/Contact/Contact";
import { SignIn } from "../pages/marketing/SignIn/SignIn";
import { Blog } from "../pages/marketing/Blog/Blog";
import { BlogContents } from "../pages/marketing/Blog/BlogContents";
import { BlogPost } from "../pages/marketing/Blog/BlogPost";
import { Newsletter } from "../pages/marketing/Newsletter/Newsletter";
import { NewsletterSuccess } from "../pages/marketing/Newsletter/NewsletterSuccess";
import { GFThemeProvider } from "../components/GFThemeProvider/GFThemeProvider";
import { Event } from "../pages/marketing/Event/Event";
import { EventDetails } from "../pages/marketing/Event/EventDetails";
import { EventSuccess } from "../pages/marketing/Event/EventSuccess";
import { EventCheckout } from "../pages/marketing/Event/EventCheckout";
import { Events } from "../pages/marketing/Event/Events";
import { handleIncomingNativeEvent } from "../lib/mobileHelpers";
import { sendToMobile, MobileActionType } from "../lib/mobile/mobileActions";
import { IncomingNativeEvent } from "../lib/NativeEvent";
import { Policy } from "../pages/marketing/Policy/Policy";
import { Legal } from "../pages/marketing/Legal/Legal";
import { Spark } from "../pages/marketing/Spark/Spark";
import { SparkDetails } from "../pages/marketing/SparkDetails/SparkDetails";
import { SparkActivities } from "../pages/marketing/Spark/SparkActivities";
import { SparkContainer } from "../components/SparkContainer/SparkContainer";
import { SparkAvailability } from "../pages/marketing/Spark/SparkAvailability";
import { SparkBirthDate } from "../pages/marketing/Spark/SparkBirthDate";
import { SparkCheckout } from "../pages/marketing/Spark/SparkCheckout";
import { SparkEmail } from "../pages/marketing/Spark/SparkEmail";
import { SparkEmailVerify } from "../pages/marketing/Spark/SparkEmailVerify";
import { SparkGender } from "../pages/marketing/Spark/SparkGender";
import { SparkGenderPreference } from "../pages/marketing/Spark/SparkGenderPreference";
import { SparkLeader } from "../pages/marketing/Spark/SparkLeader";
import { SparkName } from "../pages/marketing/Spark/SparkName";
import { SparkNeighborhoods } from "../pages/marketing/Spark/SparkNeighborhoods";
import { SparkPhoneNumber } from "../pages/marketing/Spark/SparkPhoneNumber";
import { SparkPreferences } from "../pages/marketing/Spark/SparkPreferences";
import { Profile } from "../pages/marketing/Profile/Profile";
import { Settings } from "../pages/marketing/Settings/Settings";
import { Connect } from "../pages/marketing/Connect/Connect";
import { ConnectDetails } from "../pages/marketing/Connect/ConnectDetails";
import { Matches } from "../pages/marketing/Matches/Matches";
import { UserProfile } from "../pages/marketing/UserProfile/UserProfile";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Page />,
    children: [
      {
        path: "",
        element: <Spark />,
      },
      {
        path: "app/*",
        async lazy() {
          const { App } = await import("../pages/app/App/App");
          return { Component: App };
        },
      },
      {
        path: "admin/*",
        async lazy() {
          const { Admin } = await import("../pages/admin/Admin/Admin");
          return { Component: Admin };
        },
      },
      {
        path: "team",
        async lazy() {
          const { Team } = await import("../pages/marketing/Team/Team");
          return { Component: Team };
        },
        children: [],
      },
      {
        path: "contact",
        element: <Contact />,
      },

      {
        path: "spark",
        element: <SparkContainer />,
        children: [
          { path: "", element: <Spark /> },
          { path: "activities", element: <SparkActivities /> },
          { path: "birth-date", element: <SparkBirthDate /> },
          { path: "neighborhoods", element: <SparkNeighborhoods /> },
          { path: "gender", element: <SparkGender /> },
          { path: "gender-preference", element: <SparkGenderPreference /> },
          { path: "availability", element: <SparkAvailability /> },
          { path: "name", element: <SparkName /> },
          { path: "email", element: <SparkEmail /> },
          { path: "email-verify", element: <SparkEmailVerify /> },
          { path: "phone", element: <SparkPhoneNumber /> },
          { path: "leader", element: <SparkLeader /> },
          { path: "checkout", element: <SparkCheckout /> },
          { path: "preferences", element: <SparkPreferences /> },
        ],
      },
      {
        path: "spark/atlanta/:sparkId",
        element: <SparkDetails />,
      },
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "blog",
        element: <Blog />,
        children: [
          {
            index: true,
            element: <BlogContents />,
          },
          {
            path: ":postSlug",
            element: <BlogPost />,
          },
        ],
      },
      {
        path: "event/:eventSlug",
        element: <Event />,
        children: [
          {
            index: true,
            element: <EventDetails />,
          },
          {
            path: "success",
            element: <EventSuccess />,
          },
          {
            path: "checkout",
            element: <EventCheckout />,
          },
        ],
      },
      {
        path: "events",
        element: <Events />,
      },
      {
        path: "connect",
        element: <Connect />,
      },
      {
        path: "connect/spark/:sparkId/:userId?",
        element: <ConnectDetails />,
      },
      {
        path: "matches",
        element: <Matches />,
      },
      {
        path: "profile/:userId",
        element: <UserProfile />,
      },
      {
        path: "profile",
        element: <Profile />,
      },

      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "newsletter",
        children: [
          {
            index: true,
            element: <Newsletter />,
          },
          {
            path: "success",
            element: <NewsletterSuccess />,
          },
        ],
      },
      {
        path: "legal",
        element: <Legal />,
      },
      {
        path: "policy/:slug",
        element: <Policy />,
      },
    ],
  },
  {},
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <GFThemeProvider>
      <RouterProvider router={router} />
    </GFThemeProvider>
  </React.StrictMode>
);

window.addEventListener("nativeEvent", (event: Event) => {
  const { detail } = event as CustomEvent<IncomingNativeEvent>;
  handleIncomingNativeEvent(detail);
});

sendToMobile({
  type: MobileActionType.GetSafeArea,
  params: undefined,
});

// Adjust the viewport height on load and resize
function updateVhUnit() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
}

window.addEventListener("resize", updateVhUnit);
window.addEventListener("load", updateVhUnit);

// Call it once to set the initial value
updateVhUnit();
