import { Alert, Pane, PaneProps } from "evergreen-ui";
import { useMediaBlob } from "../../lib/mediaHelpers";
import { Loader } from "../Loader/Loader";

interface MediaImageProps extends PaneProps {
  path: string;
}
export const MediaImage = ({ path, children, ...props }: MediaImageProps) => {
  const { loading, blob, error } = useMediaBlob(path);
  console.log({ loading, blob, error });
  return (
    <Pane borderRadius="0.3125rem" width="100%" overflow="hidden" {...props}>
      <Pane
        style={{
          aspectRatio: 1,
          backgroundImage: blob
            ? `url(${URL.createObjectURL(blob)}`
            : undefined,
          backgroundSize: "cover",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {loading ? (
          <Loader />
        ) : error ? (
          <Alert intent="danger" title={error} />
        ) : (
          children
        )}
      </Pane>
    </Pane>
  );
};
