import { useEffect, useState } from "react";
import { store } from "./store/store";

export const downloadMediaBlob = async (path: string) => {
  const url = new URL(`/media${path}`, import.meta.env.VITE_API_URL);
  return await fetch(url, {
    headers: { Authorization: `Bearer ${store.data.auth.token}` },
  }).then(async (response) => {
    if (!response.ok) {
      return null;
    }
    return await response.blob();
  });
};

export const useMediaBlob = (path: string) => {
  const [state, setState] = useState<{
    loading: boolean;
    blob: Blob | null;
    error?: string;
  }>({
    loading: true,
    blob: null,
  });
  useEffect(() => {
    setState((prev) => ({ ...prev, loading: true }));
    downloadMediaBlob(path)
      .then((blob) =>
        setState((prev) => ({
          ...prev,
          loading: false,
          error: undefined,
          blob,
        }))
      )
      .catch((error) =>
        setState((prev) => ({
          ...prev,
          loading: false,
          error: error.toString(),
          blob: null,
        }))
      );
  }, [path]);
  return state;
};
