import { getDateTimeRangeString, Spark } from "@greenflagdate/shared";
import { NewCard, NewCardProps } from "../Card/Card";
import {
  Pane,
  Paragraph,
  Icon,
  MapMarkerIcon,
  PaneProps,
  ChevronRightIcon,
} from "evergreen-ui";
import { H3 } from "../Heading/Heading";
import { useGFTheme } from "../../lib/themes";
import { useNavigate } from "react-router-dom";
import { getAgeRangeString } from "../../lib/sparkHelpers";
import styles from "./SparkFeedbackCard.module.css";
interface SparkCardProps extends NewCardProps {
  spark: Spark;
}

interface SparkCardImageProps extends PaneProps {
  src: string;
}

const SparkImage = ({ src, ...props }: SparkCardImageProps) => {
  return (
    <Pane
      backgroundImage={`url(${src})`}
      backgroundSize="cover"
      backgroundPosition="50% 30%"
      position="relative"
      overflow="hidden"
      {...props}
    ></Pane>
  );
};

export const SparkFeedbackCard = ({ spark, ...props }: SparkCardProps) => {
  const { colors } = useGFTheme();
  const navigate = useNavigate();

  return (
    <NewCard
      padding={0}
      overflow="hidden"
      display="flex"
      marginBottom="2rem"
      cursor="pointer"
      border={`2px solid ${colors.neutralLight}`}
      selectors={{
        "&:hover": {
          border: `2px solid ${colors.greenPrimary}`,
        },
      }}
      alignItems="center"
      onClick={() => navigate(`/connect/spark/${spark.id}`)}
      {...props}
    >
      <SparkImage src={spark.primary_image!} className={styles.bigImage} />
      <Pane flex={1} className={styles.cardContent}>
        <Pane display="flex" gap="0.5rem" flex={1}>
          <SparkImage
            src={spark.primary_image!}
            className={styles.smallImage}
          />
          <Pane>
            <H3
              textAlign="left"
              flex={1}
              marginBottom="0.5rem"
              cursor="pointer"
            >
              {spark.title}
            </H3>

            <Paragraph size={300} marginBottom="0.25rem">
              {getDateTimeRangeString(spark.starts_at, spark.ends_at)}
            </Paragraph>

            <Pane className={styles.bigScreenInfo}>
              <Paragraph size={300} marginBottom="0.35rem">
                Ages: {getAgeRangeString(spark.min_age, spark.max_age)}
              </Paragraph>
              <Paragraph
                size={300}
                marginBottom="0.35rem"
                display="flex"
                alignItems="flex-start"
                gap="0.25rem"
              >
                <Icon icon={MapMarkerIcon} marginTop="3px" /> {spark.location}
              </Paragraph>
            </Pane>
          </Pane>
        </Pane>
        <Pane marginTop="0.5rem">
          <Pane className={styles.smallScreenInfo}>
            <Paragraph size={300} marginBottom="0.35rem">
              Ages: {getAgeRangeString(spark.min_age, spark.max_age)}
            </Paragraph>
            <Paragraph
              size={300}
              marginBottom="0.35rem"
              display="flex"
              alignItems="flex-start"
              gap="0.25rem"
            >
              <Icon icon={MapMarkerIcon} marginTop="3px" /> {spark.location}
            </Paragraph>
          </Pane>
        </Pane>
      </Pane>
      <ChevronRightIcon
        size={20}
        color={colors.grayPrimary}
        marginRight="1rem"
      />
    </NewCard>
  );
};
