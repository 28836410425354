import {
  MediaKey,
  SparkWithProfiles,
  UserRelationshipType,
} from "@greenflagdate/shared";
import { useReq } from "@larner.dev/use-req";
import {
  Alert,
  Button,
  ButtonProps,
  ChevronLeftIcon,
  CrossIcon,
  Icon,
  Pane,
  Paragraph,
} from "evergreen-ui";
import { ReactNode, useCallback, useEffect, useMemo } from "react";
import { apiReq } from "../../../lib/apiReq";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../../../components/Loader/Loader";
import { NewCard } from "../../../components/Card/Card";
import { H2 } from "../../../components/Heading/Heading";
import { NotFound } from "../../../components/NotFound/NotFound";
import { MediaImage } from "../../../components/MediaImage/MediaImage";
import { useGFTheme } from "../../../lib/themes";

import sparkOutline from "./images/spark-outline.svg";
import sparkSolid from "./images/spark-solid.svg";
import { useStoreData } from "../../../lib/store/store";
import { useLoggedIn } from "../../../lib/useLoggedIn";

const ChoiceButton = ({
  hoverColor,
  iconBefore,
  children,
  ...props
}: ButtonProps & { hoverColor: string; iconBefore: ReactNode }) => {
  return (
    <Button
      appearance="minimal"
      size="large"
      width="5rem"
      height="5rem"
      borderRadius="50%"
      fontSize="0.625rem"
      lineHeight={1}
      boxShadow="0px 4px 4px 0px rgba(0,0,0,0.25)"
      color="#3A0F0F"
      flexDirection="column"
      selectors={{
        "&:hover": {
          backgroundColor: hoverColor,
        },
      }}
      {...props}
    >
      {iconBefore}
      {children}
    </Button>
  );
};

export const ConnectDetails = () => {
  const storeData = useStoreData();
  useLoggedIn(storeData);
  const { sparkId, userId } = useParams<{ sparkId: string; userId?: string }>();
  const [sparkReq, sparkReqState] = useReq<SparkWithProfiles>(apiReq);
  const [relationshipReq, relationshipReqState] = useReq<{ success: true }>(
    apiReq
  );
  const navigate = useNavigate();
  const { colors } = useGFTheme();
  useEffect(() => {
    sparkReq.get(`/spark/${sparkId}/connections`).then((spark) => {
      if (spark.success) {
        if (!userId) {
          navigate(`/connect/spark/${sparkId}/${spark.result.profiles[0].id}`, {
            replace: true,
          });
        }
      }
    });
  }, [navigate, sparkId, sparkReq, userId]);
  const profile = useMemo(
    () =>
      sparkReqState.result?.profiles.find((p) => p.id.toString() === userId),
    [sparkReqState.result?.profiles, userId]
  );
  const mediaKeys = useMemo(
    () =>
      profile
        ? (Object.values(MediaKey) as MediaKey[]).filter((k) => {
            console.log(k, !!profile.media[k]);
            return profile.media[k];
          })
        : [],
    [profile]
  );
  const setRelationship = useCallback(
    (relationship: UserRelationshipType) => async () => {
      if (!userId || !sparkId) {
        return;
      }

      const result = await relationshipReq.put("/user/relationship", {
        userId: parseInt(userId),
        sparkId: parseInt(sparkId),
        relationship,
      });
      if (result.success && sparkReqState.result) {
        const currentIndex = sparkReqState.result.profiles.findIndex(
          (p) => p.id.toString() === userId
        );
        const nextProfile = sparkReqState.result.profiles[currentIndex + 1];
        if (nextProfile) {
          navigate(`/connect/spark/${sparkId}/${nextProfile.id}`);
        } else {
          navigate("/connect");
        }
      }
    },
    [navigate, relationshipReq, sparkId, sparkReqState.result, userId]
  );

  console.log({ profile });

  return (
    <Pane
      maxWidth="40rem"
      marginX="auto"
      width="100%"
      paddingX="1.5rem"
      minHeight="100vh"
      display="flex"
      flexDirection="column"
      position="relative"
      paddingBottom="2rem"
    >
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingY="0.5rem"
        position="sticky"
        top={0}
        zIndex={1}
        backgroundColor={colors.neutralLight}
      >
        <Button
          size="small"
          appearance="minimal"
          iconBefore={ChevronLeftIcon}
          width="5.626rem"
          onClick={() => navigate("/connect")}
        >
          Back
        </Button>
        <Pane>
          {sparkReqState.loading ? <Loader /> : sparkReqState.result?.title}
        </Pane>
        <Button
          size="small"
          appearance="minimal"
          width="5.626rem"
          visibility="hidden"
        ></Button>
      </Pane>
      <NewCard
        border="none"
        borderWidth={0}
        flex={1}
        marginBottom="1.5rem"
        display="flex"
        flexDirection="column"
        alignItems={profile ? "flex-start" : "center"}
        justifyContent={profile ? "flex-start" : "center"}
      >
        {sparkReqState.loading ? (
          <Loader />
        ) : sparkReqState.error ? (
          <Alert title={sparkReqState.error.message} />
        ) : !profile ? (
          <NotFound />
        ) : (
          <>
            <H2>{profile.firstName}</H2>
            <Paragraph size={300}>{profile.age}</Paragraph>
            {mediaKeys.length ? (
              mediaKeys.map((k) => (
                <MediaImage
                  path={profile.media[k]!.urls.cropped}
                  marginBottom="2rem"
                />
              ))
            ) : (
              <Paragraph size={500} marginTop="2rem">
                {profile.firstName} hasn't completed their profile yet, but you
                can still spark a connection!
              </Paragraph>
            )}
          </>
        )}
      </NewCard>
      <Pane
        position="sticky"
        bottom={"1.5rem"}
        display="flex"
        justifyContent="space-between"
      >
        <ChoiceButton
          backgroundColor="#D84545"
          hoverColor={colors.redPrimary}
          iconBefore={
            <Pane
              is="img"
              src={sparkOutline}
              padding="0.25rem"
              width="2.25rem"
            />
          }
          onClick={setRelationship(UserRelationshipType.NoSpark)}
          isLoading={relationshipReqState.loading}
        >
          No Spark
        </ChoiceButton>
        <ChoiceButton
          backgroundColor={colors.neutralLight}
          hoverColor="white"
          iconBefore={<Icon icon={CrossIcon} size={32} />}
          onClick={setRelationship(UserRelationshipType.DidNotAttend)}
          isLoading={relationshipReqState.loading}
        >
          Didn't
          <br />
          Attend
        </ChoiceButton>
        <ChoiceButton
          backgroundColor={colors.greenLight}
          hoverColor="#4ACD90"
          iconBefore={
            <Pane is="img" src={sparkSolid} padding="0.25rem" width="2.25rem" />
          }
          onClick={setRelationship(UserRelationshipType.Spark)}
          isLoading={relationshipReqState.loading}
        >
          Spark
        </ChoiceButton>
      </Pane>
    </Pane>
  );
};
