import { Button, Dialog, DialogProps, Pane, Paragraph } from "evergreen-ui";
import { Link } from "../Link/Link";

export const GroupMeInfoDialog = (props: DialogProps) => (
  <Dialog hasFooter={false} title="How to Connect via GroupMe" {...props}>
    {({ close }) => (
      <>
        <Paragraph size={500}>
          If you prefer not to share any information, you can connect through
          the Green Flag GroupMe channel.{" "}
          <strong>
            GroupMe doesn't show your phone number to connections.
          </strong>
        </Paragraph>
        <Paragraph size={500} marginTop="1rem">
          To Connect with Matches on GroupMe:
        </Paragraph>
        <Paragraph size={500} marginTop="1rem">
          1. Download{" "}
          <Link to="https://groupme.com/" target="_blank">
            GroupMe app
          </Link>{" "}
          if you don't already have it.
        </Paragraph>
        <Paragraph size={500} marginTop="1rem">
          2. Join the{" "}
          <Link
            to="https://groupme.com/join_group/101894042/TX8d8SPk"
            target="_blank"
          >
            Green Flag Announcements Channel
          </Link>
        </Paragraph>
        <Paragraph size={500} marginTop="1rem">
          3. Click on the Channel Name
        </Paragraph>
        <Paragraph size={500} marginTop="1rem">
          4. View the List of Members
        </Paragraph>
        <Paragraph size={500} marginTop="1rem">
          5. Click on your match's name to Direct Message them
        </Paragraph>
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          marginY="3rem"
        >
          <Button onClick={close}>DONE</Button>
        </Pane>
      </>
    )}
  </Dialog>
);
