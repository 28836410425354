import classNames from "classnames";
import { Alert, Button, Dialog, Pane, Paragraph } from "evergreen-ui";
import { FormEventHandler, useCallback, useMemo, useState } from "react";
import styles from "./VerifyPhoneNumberDialog.module.css";
import { store, useStoreData } from "../../lib/store/store";
import { ActionType } from "../../lib/store/storeActions";
import {
  PublicCredentialRecord,
  UserWithMediaAndGroups,
} from "@greenflagdate/shared";
import { useReq } from "@larner.dev/use-req";
import { apiReq } from "../../lib/apiReq";
import { VerificationCode } from "../VerificationCode/VerificationCode";
import { H2 } from "../Heading/Heading";
import { PhoneField } from "../PhoneField/PhoneField";
import libpn from "google-libphonenumber";

const phoneUtil = libpn.PhoneNumberUtil.getInstance();

interface VerifyPhoneNumberDialogProps {
  isShown: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

type ConfirmPhoneNumberErrors = {
  phoneNumber?: string;
};

type VerifyFormErrors = {
  phoneNumber?: string;
  code?: string;
};

export const VerifyPhoneNumberDialog = ({
  isShown,
  onClose,
  onSuccess,
}: VerifyPhoneNumberDialogProps) => {
  const [formStage, setFormStage] = useState(0);
  const storeData = useStoreData();

  const [phoneNumberOverride, setPhoneNumberOverride] = useState<string | null>(
    null
  );
  const phoneNumber = useMemo(() => {
    if (phoneNumberOverride !== null) {
      return phoneNumberOverride;
    }
    if (!storeData.user?.phone_number_to_validate) {
      return "";
    }
    const parsedUserPhoneNumber = phoneUtil.parse(
      storeData.user.phone_number_to_validate,
      "US"
    );
    return parsedUserPhoneNumber.getNationalNumber()?.toString();
  }, [phoneNumberOverride, storeData.user?.phone_number_to_validate]);
  const [confirmationErrors, setConfirmationErrors] =
    useState<ConfirmPhoneNumberErrors>({});
  const [setPhoneNumbnerToVerifyRequest, setPhoneNumberToVerifyRequestState] =
    useReq<PublicCredentialRecord>(apiReq);
  const confirmPhoneNumber = useCallback<FormEventHandler>(
    async (e) => {
      e.preventDefault();
      const errors: ConfirmPhoneNumberErrors = {};
      if (!phoneNumber) {
        errors.phoneNumber = "Please enter your phone number";
      }

      let parsedPhoneNumber;
      try {
        parsedPhoneNumber = phoneUtil.parse(phoneNumber, "US");
        if (!phoneUtil.isValidNumberForRegion(parsedPhoneNumber, "US")) {
          errors.phoneNumber = "It looks like this phone number is invalid";
        }
      } catch (error) {
        errors.phoneNumber = "It looks like this phone number is invalid";
      }

      setConfirmationErrors(errors);
      if (!Object.keys(errors).length) {
        setPhoneNumberToVerifyRequestState.clearError();
        const formattedPhone = phoneUtil.format(
          parsedPhoneNumber!,
          libpn.PhoneNumberFormat.INTERNATIONAL
        );
        const response = await setPhoneNumbnerToVerifyRequest.post(
          "/user/phone-code",
          {
            phone: formattedPhone,
          }
        );
        if (response.success) {
          store.dispatch({
            type: ActionType.UpdateUser,
            params: {
              user: {
                ...storeData.user!,
                phone_number_to_validate: formattedPhone,
              },
            },
          });

          setFormStage(1);
          setTimeout(() => setFormStage(2), 500);
        }
      }
    },
    [
      phoneNumber,
      setPhoneNumberToVerifyRequestState,
      setPhoneNumbnerToVerifyRequest,
      storeData.user,
    ]
  );

  const [verifyErrors, setVerifyErrors] = useState<VerifyFormErrors>({});
  const [verifyRequest, verifyRequestState] =
    useReq<UserWithMediaAndGroups>(apiReq);
  const [otp, setOtp] = useState("");
  const closeDialog = useCallback(() => {
    setFormStage(0);
    setConfirmationErrors({});
    setVerifyErrors({});
    setOtp("");
    setPhoneNumberToVerifyRequestState.clearError();
    verifyRequestState.clearError();
    onClose();
  }, [setPhoneNumberToVerifyRequestState, onClose, verifyRequestState]);
  const verify = useCallback(
    async (otpOverride?: string) => {
      const errors: VerifyFormErrors = {};
      const otpVal = otpOverride || otp;

      if (!otpVal) {
        errors.code = "Please enter your code";
      }

      if (!phoneNumber) {
        errors.phoneNumber = "Please enter your phone number";
      }

      let parsedPhoneNumber;
      try {
        parsedPhoneNumber = phoneUtil.parse(phoneNumber, "US");
        if (!phoneUtil.isValidNumberForRegion(parsedPhoneNumber, "US")) {
          errors.phoneNumber = "It looks like this phone number is invalid";
        }
      } catch (error) {
        errors.phoneNumber = "It looks like this phone number is invalid";
      }

      if (errors.phoneNumber) {
        setConfirmationErrors({ phoneNumber: errors.phoneNumber });
        setFormStage(1);
        setTimeout(() => setFormStage(0), 500);
      } else if (errors.code) {
        setVerifyErrors(errors);
      } else {
        const formattedPhone = phoneUtil.format(
          parsedPhoneNumber!,
          libpn.PhoneNumberFormat.INTERNATIONAL
        );
        verifyRequestState.clearError();
        const response = await verifyRequest.post(`/user/verify-phone`, {
          code: otpVal,
          phone: formattedPhone,
        });

        if (response.success) {
          const { result } = response;
          store.dispatch({
            type: ActionType.UpdateUser,
            params: { user: result },
          });
          if (onSuccess) {
            onSuccess();
          }
          closeDialog();
        }
      }
    },
    [
      closeDialog,
      onSuccess,
      otp,
      phoneNumber,
      verifyRequest,
      verifyRequestState,
    ]
  );

  const onOtpChange = useCallback(
    (otpVal: string) => {
      setOtp(otpVal);
      if (otpVal.length === 6) {
        verify(otpVal);
      }
    },
    [verify]
  );

  return (
    <Dialog
      isShown={isShown}
      title="VERIFY PHONE NUMBER"
      onCloseComplete={closeDialog}
      hasFooter={false}
    >
      <Pane overflow="hidden">
        <Pane
          display="flex"
          className={classNames(
            styles.formStage,
            styles[`formStage${formStage}`]
          )}
        >
          {[0, 1].includes(formStage) && (
            <Pane
              flex={1}
              paddingX="0.25rem"
              boxSizing="border-box"
              is="form"
              onSubmit={confirmPhoneNumber}
            >
              <Alert
                visibility={
                  setPhoneNumberToVerifyRequestState.error
                    ? "visible"
                    : "hidden"
                }
                intent="danger"
                title="There was a problem verifying your phone number"
                marginBottom="1rem"
              >
                {setPhoneNumberToVerifyRequestState.error?.message}
              </Alert>
              <PhoneField
                onChange={(e) => setPhoneNumberOverride(e.target.value)}
                value={phoneNumber}
                validationMessage={confirmationErrors.phoneNumber}
                name="phoneNumber"
              />
              <Pane
                display="flex"
                paddingY="2rem"
                className={styles.buttonContainer}
              >
                <Button
                  appearance="minimal"
                  flex={1}
                  onClick={closeDialog}
                  type="button"
                  disabled={setPhoneNumberToVerifyRequestState.loading}
                >
                  Cancel
                </Button>
                <Button
                  appearance="primary"
                  flex={2}
                  disabled={setPhoneNumberToVerifyRequestState.loading}
                >
                  Verify
                </Button>
              </Pane>
            </Pane>
          )}
          {[1, 2].includes(formStage) && (
            <Pane
              flex={1}
              paddingX="0.25rem"
              boxSizing="border-box"
              display="flex"
              flexDirection="column"
              is="form"
              onSubmit={(e: React.FormEvent<Element>) => {
                e.preventDefault();
                verify();
              }}
            >
              <Pane
                flex={1}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                gap="1rem"
              >
                <H2>Verification Code</H2>
                <Paragraph>
                  Please check your text messages and enter the code that was
                  sent to you.
                </Paragraph>
                {(verifyErrors.code || verifyRequestState.error) && (
                  <Alert
                    intent="danger"
                    title="There was problem validating the code"
                  >
                    {verifyErrors.code || verifyRequestState.error?.toString()}
                  </Alert>
                )}
                <VerificationCode
                  value={otp}
                  onChange={onOtpChange}
                  length={6}
                />
              </Pane>
              <Pane display="flex" gap="1rem" paddingY="2rem">
                <Button
                  appearance="minimal"
                  flex={1}
                  onClick={onClose}
                  type="button"
                  disabled={verifyRequestState.loading}
                >
                  Cancel
                </Button>
                <Button
                  appearance="primary"
                  flex={2}
                  type="submit"
                  disabled={verifyRequestState.loading}
                >
                  Sign in
                </Button>
              </Pane>
            </Pane>
          )}
        </Pane>
      </Pane>
    </Dialog>
  );
};
