import {
  ContactPreferenceEnum,
  UserWithMediaAndGroups,
} from "@greenflagdate/shared";
import { Pane, Paragraph, Button, Switch, Text } from "evergreen-ui";
import { isLoggedIn } from "../../lib/isLoggedIn";
import { store, useStoreData } from "../../lib/store/store";
import { useGFTheme } from "../../lib/themes";
import { useReq } from "@larner.dev/use-req";
import { apiReq } from "../../lib/apiReq";
import { useCallback, useState } from "react";
import { ActionType } from "../../lib/store/storeActions";
import { VerifyPhoneNumberDialog } from "../VerifyPhoneNumberDialog/VerifyPhoneNumberDialog";
import { GroupMeInfoDialog } from "../GroupMeInfoDialog/GroupMeInfoDialog";

export const ContactPreferencesForm = () => {
  const storeData = useStoreData();
  const { colors } = useGFTheme();
  const [showVerifyPhoneDialog, setShowVerifyPhoneDialog] = useState(false);
  const [showGroupmeInfo, setShowGroupmeInfo] = useState(false);
  const [updateUserReq, updateUserReqState] =
    useReq<UserWithMediaAndGroups>(apiReq);
  const toggleContactPreference = useCallback(
    async (contactPref: ContactPreferenceEnum) => {
      const contactPreferences = storeData.user?.contact_preferences || [];
      const response = await updateUserReq.put("/user/attributes", {
        contact_preferences: contactPreferences.includes(contactPref)
          ? contactPreferences.filter((p) => p !== contactPref)
          : [...contactPreferences, contactPref],
      });
      if (response.success) {
        store.dispatch({
          type: ActionType.UpdateUser,
          params: { user: response.result },
        });
      }
    },
    [storeData.user?.contact_preferences, updateUserReq]
  );
  if (!isLoggedIn(storeData)) {
    return null;
  }
  return (
    <>
      <Pane display="flex" marginTop="1rem">
        <Paragraph size={300} flex={1}>
          GROUPME
        </Paragraph>
        <Paragraph size={300} width="5rem">
          Share Info?
        </Paragraph>
      </Pane>
      <Pane display="flex" marginTop="0.5rem">
        <Pane flex={1}>
          <Button
            appearance="primary"
            intent="success"
            size="small"
            backgroundColor={colors.greenLight}
            className="success"
            selectors={{
              "&.success": { color: colors.greenDark },
              "&.success:hover": {
                color: colors.neutralLight,
                background: colors.greenPrimary,
              },
            }}
            onClick={() => setShowGroupmeInfo(true)}
          >
            Join Groupme Channel
          </Button>
        </Pane>

        <Pane
          width="5rem"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Switch
            height={24}
            disabled={updateUserReqState.loading}
            checked={storeData.user?.contact_preferences.includes(
              ContactPreferenceEnum.Groupme
            )}
            onChange={() =>
              toggleContactPreference(ContactPreferenceEnum.Groupme)
            }
          />
        </Pane>
      </Pane>
      <Paragraph size={300} flex={1} marginTop="1rem">
        PHONE NUMBER
      </Paragraph>
      <Pane display="flex" marginTop="0.5rem">
        <Text flex={1}>
          {storeData.user?.phone_number ||
            storeData.user?.phone_number_to_validate}
        </Text>

        <Pane
          width="5rem"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Switch
            height={24}
            disabled={updateUserReqState.loading}
            checked={storeData.user?.contact_preferences.includes(
              ContactPreferenceEnum.Phone
            )}
            onChange={() =>
              storeData.user?.phone_number
                ? toggleContactPreference(ContactPreferenceEnum.Phone)
                : setShowVerifyPhoneDialog(true)
            }
          />
        </Pane>
      </Pane>
      <Paragraph size={300} flex={1} marginTop="1rem">
        EMAIL
      </Paragraph>
      <Pane display="flex" marginTop="0.5rem">
        <Text flex={1}>{storeData.user?.email}</Text>

        <Pane
          width="5rem"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Switch
            height={24}
            disabled={updateUserReqState.loading}
            checked={storeData.user?.contact_preferences.includes(
              ContactPreferenceEnum.Email
            )}
            onChange={() =>
              toggleContactPreference(ContactPreferenceEnum.Email)
            }
          />
        </Pane>
      </Pane>
      <VerifyPhoneNumberDialog
        isShown={showVerifyPhoneDialog}
        onClose={() => setShowVerifyPhoneDialog(false)}
        onSuccess={() => toggleContactPreference(ContactPreferenceEnum.Phone)}
      />
      <GroupMeInfoDialog
        isShown={showGroupmeInfo}
        onCloseComplete={() => setShowGroupmeInfo(false)}
      />
    </>
  );
};
