import { UserProfile, UserRelationshipType } from "@greenflagdate/shared";
import { Pane, Icon, ChevronRightIcon, PaneProps, Button } from "evergreen-ui";
import { useNavigate } from "react-router-dom";
import { useGFTheme } from "../../lib/themes";
import { NewCard } from "../Card/Card";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MediaImage } from "../MediaImage/MediaImage";
import { useCallback } from "react";
import { useReq } from "@larner.dev/use-req";
import { apiReq } from "../../lib/apiReq";

interface InvitationCardProps extends PaneProps {
  profile: UserProfile;
  sparkId: number | null;
  onResponse?: (relationship: UserRelationshipType) => void;
  onResponseError?: (error: string) => void;
}

export const InvitationCard = ({
  profile,
  sparkId,
  onResponse,
  onResponseError,
  ...props
}: InvitationCardProps) => {
  const { colors } = useGFTheme();
  const navigate = useNavigate();
  const [responseReq, responseReqState] = useReq(apiReq);
  const respond = useCallback(
    (relationship: UserRelationshipType) => async () => {
      const response = await responseReq.put("/user/relationship", {
        userId: profile.id,
        relationship,
        sparkId,
      });
      if (response.success && onResponse) {
        onResponse(relationship);
      } else if (!response.success && onResponseError) {
        onResponseError(response.error.message);
      }
    },
    [onResponse, onResponseError, profile.id, responseReq, sparkId]
  );

  const picturePath = (
    profile.media.ProfilePictureHeadshot ||
    profile.media.ProfilePictureBody ||
    profile.media.ProfilePictureAction ||
    profile.media.ProfilePicturePet ||
    profile.media.ProfilePictureTravel ||
    profile.media.ProfilePictureFriends
  )?.urls.cropped;
  return (
    <NewCard padding="0" overflow="hidden" {...props}>
      <Pane
        display="flex"
        gap="1rem"
        cursor="pointer"
        onClick={() =>
          navigate(`/profile/${profile.id}`, { state: { back: "/connect" } })
        }
      >
        {picturePath ? (
          <MediaImage
            path={picturePath}
            width="6rem"
            height="6rem"
            borderRightWidth="2px"
          />
        ) : (
          <Pane
            width="6rem"
            height="6rem"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRight="2px solid black"
          >
            <FontAwesomeIcon
              icon={faImage}
              color={colors.neutralMedium}
              size={"2x"}
            />
          </Pane>
        )}
        <Pane flex={1}>
          <Pane
            paddingY="0.5rem"
            fontSize="1rem"
            fontWeight="600"
            color={colors.greenDark}
          >
            {profile.firstName}
          </Pane>
          {!!profile.age && (
            <Pane color={colors.greenDark}>Age: {profile.age}</Pane>
          )}
        </Pane>
        <Pane display="flex" alignItems="center" paddingRight="0.5rem">
          <Icon icon={ChevronRightIcon} />
        </Pane>
      </Pane>
      <Pane>
        <Pane display="flex" borderTop="2px solid black">
          <Pane
            flex={1}
            paddingY="0.5rem"
            paddingX="1rem"
            borderRight="2px solid black"
          >
            <Button
              width="100%"
              size="small"
              onClick={respond(UserRelationshipType.Declined)}
              isLoading={responseReqState.loading}
            >
              Decline Connection
            </Button>
          </Pane>
          <Pane flex={1} paddingY="0.5rem" paddingX="1rem">
            <Button
              width="100%"
              size="small"
              onClick={respond(UserRelationshipType.Spark)}
              isLoading={responseReqState.loading}
            >
              Connect
            </Button>
          </Pane>
        </Pane>
      </Pane>
    </NewCard>
  );
};
