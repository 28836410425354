import { Button, Pane, Paragraph } from "evergreen-ui";
import { H2 } from "../../../components/Heading/Heading";
import { useReq } from "@larner.dev/use-req";
import { apiReq } from "../../../lib/apiReq";
import { useEffect, useState } from "react";
import { useStoreData } from "../../../lib/store/store";
import { useLoggedIn } from "../../../lib/useLoggedIn";
import { Loader } from "../../../components/Loader/Loader";
import { useGFTheme } from "../../../lib/themes";
import { Spark, UserProfile } from "@greenflagdate/shared";
import { SparkFeedbackCard } from "../../../components/SparkCard/SparkFeedbackCard";
import { Alert } from "../../../components/Alert/Alert";
import { useNavigate } from "react-router-dom";
import { MatchFeatureWall } from "../../../components/MatchFeatureWall/MatchFeatureWall";
import { InvitationCard } from "../../../components/InvitationCard/InvitationCard";
import { BlurredInvitationCard } from "../../../components/InvitationCard/BlurredInvitationCard";

export const Connect = () => {
  const storeData = useStoreData();
  const navigate = useNavigate();
  useLoggedIn(storeData);
  const [pendingSparks, pendingSparksState] = useReq<Spark[]>(apiReq);
  const [invitationsReq, invitationsReqState] =
    useReq<{ sparkId: number | null; profile: UserProfile }[]>(apiReq);
  const [invitationResponseError, setInvitationResponseError] = useState("");
  useReq<UserProfile[]>(apiReq);
  useEffect(() => {
    pendingSparks.get("/spark/pending-feedback");
    invitationsReq.get("/user/invitations");
  }, [invitationsReq, pendingSparks]);
  const { colors } = useGFTheme();
  return (
    <MatchFeatureWall
      storeData={storeData}
      maxWidth="40rem"
      marginX="auto"
      marginTop="1.5rem"
      paddingX="1.5rem"
      paddingBottom="2rem"
    >
      <H2 marginBottom="1rem">Connect</H2>
      {pendingSparksState.loading ? (
        <Loader />
      ) : pendingSparksState.error || !pendingSparksState.result ? (
        <Alert
          title={pendingSparksState.error?.message || "Something went wrong"}
        />
      ) : invitationsReqState.error || !invitationsReqState.result ? (
        <Alert
          title={invitationsReqState.error?.message || "Something went wrong"}
        />
      ) : invitationResponseError ? (
        <Alert title={invitationResponseError} />
      ) : pendingSparksState.result.length ||
        invitationsReqState.result.length ? (
        <>
          {!!invitationsReqState.result.length && (
            <Pane marginBottom="2rem">
              <Paragraph size={400} color={colors.greenDark}>
                LIKES YOU
              </Paragraph>
              {pendingSparksState.result.length ? (
                <BlurredInvitationCard
                  profile={invitationsReqState.result[0].profile}
                  count={invitationsReqState.result.length}
                  spark={pendingSparksState.result[0]}
                />
              ) : (
                invitationsReqState.result.map((p) => (
                  <InvitationCard
                    key={p.profile.id}
                    sparkId={p.sparkId}
                    profile={p.profile}
                    marginBottom="1rem"
                    onResponse={() => navigate("/matches")}
                    onResponseError={setInvitationResponseError}
                  />
                ))
              )}
            </Pane>
          )}
          {!!pendingSparksState.result.length && (
            <>
              <Paragraph size={400} color={colors.greenDark}>
                SUBMIT A MATCH
              </Paragraph>
              {pendingSparksState.result.map((s) => (
                <SparkFeedbackCard key={s.id} spark={s} />
              ))}
            </>
          )}
        </>
      ) : (
        <Pane
          display="flex"
          flexDirection="column"
          gap="2rem"
          alignItems="center"
        >
          <Paragraph size={500} width="100%">
            It seems a little quiet here... Join one of our events to start
            connecting!
          </Paragraph>
          <Button
            color={colors.greenDark}
            backgroundColor={colors.greenLight}
            border={colors.greenLight}
            maxWidth="20rem"
            onClick={() => navigate("/events")}
          >
            Explore Upcoming Events
          </Button>
          <Paragraph size={500} width="100%">
            At our events, you'll:
            <br />
            ✨ Meet other singles in person. <br />
            ✨ Break the ice with fun activities.
            <br />✨ Start building real connections.
          </Paragraph>
          <Paragraph size={500}>
            Your next connection could be just one event away. We can't wait to
            see you there! 💚
          </Paragraph>
        </Pane>
      )}
    </MatchFeatureWall>
  );
};
