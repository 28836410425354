import classNames from "classnames";
import { MouseEventHandler, useMemo, useState } from "react";
import { Hamburger } from "./Hamburger";
import styles from "./MarketingHeader.module.css";
import logo from "./images/logo.png";
import { NavLink, useLocation } from "react-router-dom";
import { store, useStoreData } from "../../lib/store/store";
import { isLoggedIn } from "../../lib/isLoggedIn";
import { ActionType } from "../../lib/store/storeActions";
import { LoginDialog } from "../LoginDialog/LoginDialog";
import { Pane } from "evergreen-ui";
import { LoggedInNav } from "../LoggedInNav/LoggedInNav";

export function MarketingHeader() {
  const [navOpen, setNavOpen] = useState(false);
  const storeData = useStoreData();
  const loggedIn = useMemo(() => isLoggedIn(storeData), [storeData]);
  const [loginDialogShown, setLoginDialogShown] = useState(false);
  const location = useLocation();

  if (location.pathname.startsWith("/connect/spark")) {
    return null;
  }

  const onNavClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    setNavOpen(false);
    const url = new URL(e.currentTarget.href);
    if (url.pathname === "/") {
      const el = document.getElementById(url.hash.substring(1));
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
        e.preventDefault();
      }
    }
  };
  return (
    <>
      <Pane
        className={styles.content}
        top={0}
        position={loggedIn ? "static" : "sticky"}
      >
        <div className={styles.logoSpacer}>
          <NavLink to="/" className={styles.logoLink}>
            <img src={logo} alt="Green Flag logo" height={35} />
          </NavLink>
        </div>

        <Hamburger
          open={navOpen}
          onClick={setNavOpen}
          className={styles.hamburger}
        />
        <div
          className={classNames(styles.navLinks, { [styles.navOpen]: navOpen })}
        >
          <NavLink
            to="/events"
            onClick={onNavClick}
            className={({ isActive }) => (isActive ? styles.active : "")}
          >
            EVENTS
          </NavLink>
          <NavLink
            to="/team"
            onClick={onNavClick}
            className={({ isActive }) => (isActive ? styles.active : "")}
          >
            TEAM
          </NavLink>
          <NavLink
            to="/contact"
            onClick={onNavClick}
            className={({ isActive }) => (isActive ? styles.active : "")}
          >
            CONTACT
          </NavLink>
          {!loggedIn ? (
            <NavLink
              to="#"
              onClick={(e) => {
                setLoginDialogShown(true);
                onNavClick(e);
              }}
              className={styles.lastTextNavLink}
            >
              SIGN IN
            </NavLink>
          ) : (
            <NavLink
              to="#"
              onClick={(e) => {
                store.dispatch({
                  type: ActionType.LogOut,
                  params: {},
                });
                onNavClick(e);
              }}
              className={styles.lastTextNavLink}
            >
              SIGN OUT
            </NavLink>
          )}
        </div>
        <LoginDialog
          isShown={loginDialogShown}
          onClose={() => setLoginDialogShown(false)}
        />
      </Pane>
      {loggedIn && <LoggedInNav />}
    </>
  );
}
