import {
  ContactPreferenceEnum,
  UserProfileWithContactInfo,
} from "@greenflagdate/shared";
import {
  Button,
  ChevronDownIcon,
  ChevronRightIcon,
  EnvelopeIcon,
  Icon,
  Pane,
  PaneProps,
  PhoneIcon,
  Text,
} from "evergreen-ui";
import { MediaImage } from "../MediaImage/MediaImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { useGFTheme } from "../../lib/themes";
import { NewCard } from "../Card/Card";
import { useState } from "react";
import groupMe from "./images/GroupMe.png";
import { GroupMeInfoDialog } from "../GroupMeInfoDialog/GroupMeInfoDialog";
import { UnmatchDialog } from "../UnmatchDialog/UnmatchDialog";
import { useNavigate } from "react-router-dom";

interface MatchCardProps extends PaneProps {
  profile: UserProfileWithContactInfo;
  onUnmatch?: () => void;
}

export const MatchCard = ({ profile, onUnmatch, ...props }: MatchCardProps) => {
  const { colors } = useGFTheme();
  const [expanded, setExpanded] = useState(false);
  const [showGroupmeInfo, setShowGroupmeInfo] = useState(false);
  const [confirmUnmatch, setConfirmUnmatch] = useState(false);
  const navigate = useNavigate();

  const picturePath = (
    profile.media.ProfilePictureHeadshot ||
    profile.media.ProfilePictureBody ||
    profile.media.ProfilePictureAction ||
    profile.media.ProfilePicturePet ||
    profile.media.ProfilePictureTravel ||
    profile.media.ProfilePictureFriends
  )?.urls.cropped;
  return (
    <NewCard padding="0" overflow="hidden" {...props}>
      <Pane
        display="flex"
        gap="1rem"
        cursor="pointer"
        borderBottom={expanded ? "2px solid black" : "none"}
        onClick={() => setExpanded(!expanded)}
      >
        {picturePath ? (
          <MediaImage
            path={picturePath}
            width="6rem"
            height="6rem"
            borderRightWidth="2px"
          />
        ) : (
          <Pane
            width="6rem"
            height="6rem"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRight="2px solid black"
          >
            <FontAwesomeIcon
              icon={faImage}
              color={colors.neutralMedium}
              size={"2x"}
            />
          </Pane>
        )}
        <Pane flex={1}>
          <Pane
            paddingY="0.5rem"
            fontSize="1rem"
            fontWeight="600"
            color={colors.greenDark}
          >
            {profile.firstName}
          </Pane>
          {!!profile.age && (
            <Pane color={colors.greenDark}>Age: {profile.age}</Pane>
          )}
        </Pane>
        <Pane display="flex" alignItems="center" paddingRight="0.5rem">
          <Icon icon={expanded ? ChevronDownIcon : ChevronRightIcon} />
        </Pane>
      </Pane>
      {expanded && (
        <Pane>
          <Pane display="flex" borderBottom="2px solid black">
            <Pane
              flex={1}
              paddingY="0.5rem"
              paddingX="1rem"
              borderRight="2px solid black"
            >
              <Button
                width="100%"
                size="small"
                onClick={() => setConfirmUnmatch(true)}
              >
                Unmatch
              </Button>
            </Pane>
            <Pane flex={1} paddingY="0.5rem" paddingX="1rem">
              <Button
                width="100%"
                size="small"
                onClick={() =>
                  navigate(`/profile/${profile.id}`, {
                    state: { back: "/matches" },
                  })
                }
              >
                View Profile
              </Button>
            </Pane>
          </Pane>
          <Pane>
            {!!profile.phoneNumber && (
              <Pane
                display="flex"
                alignItems="center"
                padding="0.5rem"
                gap="0.5rem"
              >
                <Icon icon={PhoneIcon} width="19px" height="19px" />
                <Text
                  is="a"
                  href={`sms:${profile.phoneNumber}`}
                  textDecoration="underline"
                >
                  {profile.phoneNumber}
                </Text>
              </Pane>
            )}
            {!!profile.email && (
              <Pane
                display="flex"
                alignItems="center"
                padding="0.5rem"
                gap="0.5rem"
              >
                <Icon icon={EnvelopeIcon} width="19px" height="19px" />
                <Text>{profile.email}</Text>
              </Pane>
            )}
            {(profile.contactPreferences.includes(
              ContactPreferenceEnum.Groupme
            ) ||
              !profile.contactPreferences.length) && (
              <Pane
                display="flex"
                alignItems="center"
                padding="0.5rem"
                gap="0.5rem"
                cursor="pointer"
                selectors={{
                  "&:hover": { backgroundColor: colors.neutralLight },
                }}
                onClick={() => setShowGroupmeInfo(true)}
              >
                <Pane is="img" src={groupMe} width="19px" height="19px" />
                <Text flex={1}>Connect via GroupMe</Text>
                <Icon icon={ChevronRightIcon} />
              </Pane>
            )}
          </Pane>
        </Pane>
      )}
      <GroupMeInfoDialog
        isShown={showGroupmeInfo}
        onCloseComplete={() => setShowGroupmeInfo(false)}
      />
      <UnmatchDialog
        isShown={confirmUnmatch}
        onCloseComplete={() => setConfirmUnmatch(false)}
        unmatchUserProfile={profile}
        onUnmatch={onUnmatch}
      />
    </NewCard>
  );
};
