import { Spark, UserProfile } from "@greenflagdate/shared";
import { Button, Pane, PaneProps, Text } from "evergreen-ui";
import { useNavigate } from "react-router-dom";
import { useGFTheme } from "../../lib/themes";
import { NewCard } from "../Card/Card";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MediaImage } from "../MediaImage/MediaImage";

interface BlurredInvitationCardProps extends PaneProps {
  profile: UserProfile;
  count: number;
  spark: Spark;
}

export const BlurredInvitationCard = ({
  profile,
  count,
  spark,
  ...props
}: BlurredInvitationCardProps) => {
  const { colors } = useGFTheme();
  const navigate = useNavigate();

  const picturePath = (
    profile.media.ProfilePictureHeadshot ||
    profile.media.ProfilePictureBody ||
    profile.media.ProfilePictureAction ||
    profile.media.ProfilePicturePet ||
    profile.media.ProfilePictureTravel ||
    profile.media.ProfilePictureFriends
  )?.urls.cropped;
  return (
    <NewCard padding="0" overflow="hidden" {...props}>
      <Pane display="flex" gap="1rem" cursor="pointer">
        {picturePath ? (
          <MediaImage
            path={picturePath}
            width="6rem"
            height="6rem"
            borderRightWidth="2px"
            style={{
              filter: "blur(8px)",
            }}
          />
        ) : (
          <Pane
            width="6rem"
            height="6rem"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRight="2px solid black"
          >
            <FontAwesomeIcon
              icon={faImage}
              color={colors.neutralMedium}
              size={"2x"}
            />
          </Pane>
        )}
        <Pane flex={1} padding="0.5rem">
          <Text
            fontWeight={600}
            fontSize="1rem"
            paddingY="0.5rem"
            display="block"
          >
            ❤️ {count === 1 ? "Someone Likes" : `${count} People Like`} You!
          </Text>
          <Pane fontSize="0.85rem">
            Submit your matches for <strong>{spark.title}</strong> to find out
            who!
          </Pane>
          <Button
            backgroundColor={colors.greenLight}
            size="small"
            marginTop="1rem"
            onClick={() => navigate(`/connect/spark/${spark.id}`)}
          >
            SUBMIT YOUR MATCHES
          </Button>
        </Pane>
      </Pane>
      {Array.from(Array(count < 4 ? count - 1 : 4).keys()).map(() => (
        <Pane
          borderTop="none"
          borderRight="none"
          borderLeft="none"
          borderRadius="10px"
          borderBottomWidth="2px"
          borderStyle="solid"
          height="2rem"
          marginBottom="0.5rem"
          marginTop="-2rem"
        />
      ))}
    </NewCard>
  );
};
