import { Button, Pane, PaneProps, Paragraph } from "evergreen-ui";
import { StoreSchema } from "../../lib/store/StoreSchema";
import { H2 } from "../Heading/Heading";
import { useNavigate } from "react-router-dom";

export const MatchFeatureWall = ({
  children,
  storeData,
  ...props
}: PaneProps & { storeData: StoreSchema }) => {
  const navigate = useNavigate();
  return (
    <Pane {...props}>
      {storeData.user && !Object.values(storeData.user?.media || []).length ? (
        <Pane display="flex" flexDirection="column" justifyContent="center">
          <H2>Add a profile picture to start connecting!</H2>
          <Paragraph size={500} marginTop="2rem">
            Add at least one photo that clearly shows your face to your profile
            so potential matches can recognize you.{" "}
            <strong>Head to your profile to get started!</strong>
          </Paragraph>
          <Button marginTop="2rem" onClick={() => navigate("/profile")}>
            ADD PROFILE PHOTOS
          </Button>
        </Pane>
      ) : storeData.user && !storeData.user.contact_preferences.length ? (
        <Pane display="flex" flexDirection="column" justifyContent="center">
          <H2>Set your contact preferences to start connecting!</H2>
          <Paragraph size={500} marginTop="2rem">
            Choose what information you want to share with mutual matches in
            order to connect.{" "}
            <strong>Head to your settings to get started!</strong>
          </Paragraph>
          <Button marginTop="2rem" onClick={() => navigate("/settings")}>
            SET CONTACT PREFERENCES
          </Button>
        </Pane>
      ) : (
        children
      )}
    </Pane>
  );
};
