import { Alert, Button, Pane, Paragraph } from "evergreen-ui";
import { H2 } from "../../../components/Heading/Heading";
import { useReq } from "@larner.dev/use-req";
import { apiReq } from "../../../lib/apiReq";
import { UserProfileWithContactInfo } from "@greenflagdate/shared";
import { useEffect } from "react";
import { LoaderScreen } from "../../../components/LoaderScreen/LoaderScreen";
import { useGFTheme } from "../../../lib/themes";
import { useNavigate } from "react-router-dom";
import { MatchCard } from "../../../components/MatchCard/MatchCard";

export const Matches = () => {
  const { colors } = useGFTheme();
  const navigate = useNavigate();
  const [matchesReq, matchesReqState] =
    useReq<UserProfileWithContactInfo[]>(apiReq);
  useEffect(() => {
    matchesReq.get("/user/matches");
  }, [matchesReq]);
  return (
    <Pane
      maxWidth="40rem"
      marginX="auto"
      marginTop="1.5rem"
      paddingX="1.5rem"
      paddingBottom="2rem"
      width="100%"
    >
      <H2 marginBottom="1rem">My Matches</H2>
      {matchesReqState.loading ? (
        <LoaderScreen />
      ) : matchesReqState.error ? (
        <Alert title={matchesReqState.error.message} />
      ) : !matchesReqState.result?.length ? (
        <Pane
          display="flex"
          flexDirection="column"
          gap="2rem"
          alignItems="center"
        >
          <Paragraph size={500} width="100%">
            It seems a little quiet here... Join one of our events to start
            connecting!
          </Paragraph>
          <Button
            color={colors.greenDark}
            backgroundColor={colors.greenLight}
            border={colors.greenLight}
            maxWidth="20rem"
            onClick={() => navigate("/events")}
          >
            Explore Upcoming Events
          </Button>
          <Paragraph size={500} width="100%">
            At our events, you'll:
            <br />
            ✨ Meet other singles in person. <br />
            ✨ Break the ice with fun activities.
            <br />✨ Start building real connections.
          </Paragraph>
          <Paragraph size={500}>
            Your next connection could be just one event away. We can't wait to
            see you there! 💚
          </Paragraph>
        </Pane>
      ) : (
        <Pane>
          {matchesReqState.result.map((p) => (
            <MatchCard
              profile={p}
              onUnmatch={() => matchesReq.get("/user/matches")}
              marginBottom="0.5rem"
            />
          ))}
        </Pane>
      )}
    </Pane>
  );
};
