import { Alert, Button, ChevronLeftIcon, Pane, Paragraph } from "evergreen-ui";
import { H2 } from "../../../components/Heading/Heading";
import { useReq } from "@larner.dev/use-req";
import { apiReq } from "../../../lib/apiReq";
import { useEffect, useMemo, useState } from "react";
import { useGFTheme } from "../../../lib/themes";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  MediaKey,
  UserProfile as UserProfileType,
  UserProfileWithContactInfo,
} from "@greenflagdate/shared";
import { NewCard } from "../../../components/Card/Card";
import { Loader } from "../../../components/Loader/Loader";
import { MediaImage } from "../../../components/MediaImage/MediaImage";
import { NotFound } from "../../../components/NotFound/NotFound";
import { useStoreData } from "../../../lib/store/store";
import { useLoggedIn } from "../../../lib/useLoggedIn";
import { UnmatchDialog } from "../../../components/UnmatchDialog/UnmatchDialog";

export const UserProfile = () => {
  const storeData = useStoreData();
  useLoggedIn(storeData);
  const { state } = useLocation();
  const { userId } = useParams<{ userId: string }>();
  const [profileReq, profileReqState] = useReq<
    UserProfileType | UserProfileWithContactInfo
  >(apiReq);
  const navigate = useNavigate();
  const { colors } = useGFTheme();
  const [confirmUnmatch, setConfirmUnmatch] = useState(false);
  useEffect(() => {
    profileReq.get(`/user/profile/${userId}`);
  }, [profileReq, userId]);
  const mediaKeys = useMemo(
    () =>
      profileReqState.result
        ? (Object.values(MediaKey) as MediaKey[]).filter((k) => {
            return profileReqState.result!.media[k];
          })
        : [],
    [profileReqState.result]
  );

  return (
    <Pane
      maxWidth="40rem"
      marginX="auto"
      width="100%"
      paddingX="1.5rem"
      minHeight="100vh"
      display="flex"
      flexDirection="column"
      position="relative"
      paddingBottom="2rem"
    >
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingY="0.5rem"
        position="sticky"
        top={0}
        zIndex={1}
        backgroundColor={colors.neutralLight}
      >
        {state?.back && (
          <Button
            size="small"
            appearance="minimal"
            iconBefore={ChevronLeftIcon}
            width="5.626rem"
            onClick={() => navigate(state.back)}
          >
            Back
          </Button>
        )}

        {!!(profileReqState.result as UserProfileWithContactInfo)
          ?.contactPreferences && (
          <Button
            size="small"
            appearance="minimal"
            onClick={() => setConfirmUnmatch(true)}
          >
            Unmatch
          </Button>
        )}
      </Pane>
      <NewCard
        border="none"
        borderWidth={0}
        flex={1}
        marginBottom="1.5rem"
        display="flex"
        flexDirection="column"
        alignItems={profileReqState.result ? "flex-start" : "center"}
        justifyContent={profileReqState.result ? "flex-start" : "center"}
      >
        {profileReqState.loading ? (
          <Loader />
        ) : profileReqState.error ? (
          <Alert title={profileReqState.error.message} />
        ) : !profileReqState.result ? (
          <NotFound />
        ) : (
          <>
            <H2>{profileReqState.result.firstName}</H2>
            <Paragraph size={300}>{profileReqState.result.age}</Paragraph>
            {mediaKeys.length ? (
              mediaKeys.map((k) => (
                <MediaImage
                  path={profileReqState.result!.media[k]!.urls.cropped}
                  marginBottom="2rem"
                />
              ))
            ) : (
              <Paragraph size={500} marginTop="2rem">
                {profileReqState.result.firstName} hasn't completed their
                profile yet, but you can still spark a connection!
              </Paragraph>
            )}
            <UnmatchDialog
              isShown={confirmUnmatch}
              onCloseComplete={() => setConfirmUnmatch(false)}
              unmatchUserProfile={profileReqState.result}
              onUnmatch={() => navigate("/matches")}
            />
          </>
        )}
      </NewCard>
    </Pane>
  );
};
